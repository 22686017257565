<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 98 106"
  >
    <path
      fill="currentColor"
      d="M52.8 4.3v11.3a3.7 3.7 0 1 1-7.5 0V4.3a3.7 3.7 0 0 1 7.5 0Zm18.7 16.9a3.8 3.8 0 0 0-3.8 3.7v11.3a3.7 3.7 0 1 0 7.5 0V24.9a3.8 3.8 0 0 0-3.7-3.7ZM49 86.8a3.7 3.7 0 0 0-3.8 3.8v11.2a3.8 3.8 0 0 0 7.5 0V90.6a3.8 3.8 0 0 0-3.7-3.8Zm0-60a3.7 3.7 0 0 0-3.8 3.8v45a3.8 3.8 0 0 0 7.5 0v-45a3.8 3.8 0 0 0-3.7-3.8Zm-22.5-5.6a3.7 3.7 0 0 0-3.8 3.7v30a3.8 3.8 0 0 0 7.6 0V25a3.8 3.8 0 0 0-3.8-3.7Zm45 26.2a3.8 3.8 0 0 0-3.8 3.8v30a3.8 3.8 0 0 0 7.5 0v-30a3.8 3.8 0 0 0-3.7-3.8ZM4 43.7a3.8 3.8 0 0 0-3.8 3.7v11.3a3.8 3.8 0 1 0 7.5 0V47.4A3.8 3.8 0 0 0 4 43.7Zm22.5 22.5a3.7 3.7 0 0 0-3.8 3.7v11.3a3.8 3.8 0 0 0 7.6 0V69.9a3.8 3.8 0 0 0-3.8-3.7ZM94 43.7a3.8 3.8 0 0 0-3.8 3.7v11.3a3.8 3.8 0 0 0 7.5 0V47.4a3.8 3.8 0 0 0-3.7-3.7Z"
    />
  </svg>
</template>
